import React from "react";
import splash from "../assets/altus-hero-mp4.mp4";

import "./splash.css";

const Splash = () => (
  <div className="" id="home">
    <div className="splash-container">
      <div className="splash-copy">
        <h1>Altus Ventures</h1>
        <h2>Pioneering Innovation & Technology Since 2013</h2>
        <p>
          Altus Ventures is a Research and Development incubator, built to
          transform ideas into fully operational businesses. We have established
          a network of companies in the UK, the EU and Australia, ranging from
          fintech to cybersecurity. Our businesses are designed to help
          financial service providers better serve their clients.
        </p>
      </div>
      <div className="svg-container">
        <video
          className="videoTag"
          autoPlay={true}
          loop={true}
          muted={true}
          controls={false}
        >
          <source src={splash} type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
);

export default Splash;
